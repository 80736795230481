import { small, medium, large, extraLarge } from './constants';

let pageState = 1;

const livroFactory = (props) => {
    const { 
        livroLink, 
        foto,
        id,
        titulo,
        autores,
    } = props;

    const livroTemplate = `
        <div class="col-6 col-md-3 col-lg-3 col-xl-2 align-items-stretch livro">
            <div class="d-flex align-items-start flex-column box">
                <div class="capa-livro">
                    <a href="${livroLink}"> 
                    <img src="${makeLivroFotoUrl(id, foto)}" width="" class="img-fluid full-width"/></a>
                </div>
                
                <div class="p-2">
                    <h5 class="titulo"><a href="${livroLink}">${titulo}</a></h5>
                
                    <h4 class="autores">${autores}</h4>
                </div>
                <div class="mt-auto" style="width:100%;">
                    <a href="${livroLink}" 
                    class="bt-baixar">Baixar Grátis</a></div>
            </div>
        </div>
    `;

    return livroTemplate;
}

const makeLivroFotoUrl = (livroId, livroFoto) => {
    const rootUrl = location.protocol + '//' + location.host;
    return `${rootUrl}/livro/${livroId}/${livroFoto}`;
}

const loadLivrosOnLoadPage = () => {
    getLivros(detectTotalWidthAndDefineBooksLimit());
}

const detectTotalWidthAndDefineBooksLimit = () => {
    const screenWidth = $(window).width();
    let initialLimit;

    if (screenWidth <= small) {
        initialLimit = 6;
    } else if (screenWidth >= medium && screenWidth <= large) {
        initialLimit = 8;
    } else if (screenWidth >= large && screenWidth <= extraLarge) {
        initialLimit = 10;
    } else {
        initialLimit = 14;
    }

    return initialLimit;
}

const getLivros = (limit = 6, page = 1) => {
    pageState = page;

    $.ajax({
        beforeSend: function() {},
        url: "/home/home/livros-ajax",
        cache: false,
        dataType: "json",
        method: 'GET',
        data: {'limit': limit, 'ok-ajax': true, 'page': page},
        success: function(data) {
            data.map((livro) => {
                appendLivroOnPageView(livro);
            });

            if (data.length === 0 || data.length < limit) {
                $('.btn-editora').addClass('d-none');
            }
        }
    });
}

const appendLivroOnPageView = (livro) => {
    $('#livros-home').append(livroFactory(livro));
}

loadLivrosOnLoadPage();

setTimeout(() => {
    $('.btn-editora').click(() => { 
        getLivros(detectTotalWidthAndDefineBooksLimit(), pageState + 1);
    });
}, 800);
